@import "./variables.scss";

.cardBorder {
  background-color: $white;
  border: 0.5px solid $border-gray;
  box-shadow: 1px 5px 10px rgba($color: #000, $alpha: 0.05);
  border-radius: 0.3em;
}

.noBorder {
  border: none;
  box-shadow: none;
}

.noBackground {
  background: none;
}

.blxpPage {
  background-color: $white;
  .positionBottom {
    position: absolute;
    bottom: 2em;
  }
  .headerLarge {
    font-size: 16px;
  }
  .textLarge {
    font-size: 18px;
  }
  .textSmall {
    margin-top: 1.25em;
    font-size: 15px;
  }
  .iconRight {
    margin-left: 0.5em;
    font-size: 0.7em;
  }

  .iconRightSmall {
    position: relative;
    top: 0.2em;
    left: 0.3em;
  }
  .iconRightLarge {
    margin-left: 0.5em;
    margin-top: 0.35em;
    font-size: 0.9em;
  }
  .iconLeft {
    margin-left: -0.5em;
    position: relative;
    top: 0.15em;
  }
  .iconGreen {
    color: $text-green-success;
  }
  .introContainer {
    padding: 2em;
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
    .homeOverviewWraper {
      position: relative;
      width: 44%;
      min-width: 20em;
      padding: 1em;
      .homeOverviewTitle {
        display: flex;
        flex-direction: column;
        gap: 0.25em;
      }
      .textContainer {
        display: flex;
        flex-direction: column;
        gap: 4em;
      }
    }
  }
  .progressChartContainer {
    padding: 0 2em;
  }
  .backToTopButton {
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 99;
    border: none;
    outline: none;
    color: $black;
    cursor: pointer;
    padding: 15px;
    font-size: 12px;
  }
}

.tooltipStyles {
  position: relative !important;
  padding: 0 0 0 0 !important;
}

//Tab
@media only screen and (min-width: 426px) and (max-width: 950px) {
  .blxpPage {
    .introContainer {
      flex-direction: column;
      .homeOverviewWraper {
        width: 95.8%;
      }
    }
  }
}

//Mobile
@media only screen and (max-width: 425px) {
  .blxpPage {
    .introContainer {
      flex-direction: column;
      padding: 1em;
      .homeOverviewWraper {
        width: 92%;
      }
    }
  }
}
