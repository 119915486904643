@import './variables.scss';

.cardStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.textStyle {
  font-size: 1.5rem !important;
  margin: 0em 1em 0em 1em;
}

.imageStyle {
  width: 40%;
  border-radius: 1em;
  border: 1em solid var(--color-neutralForeground1);
  margin: 1em 1em 1em 1em;
}