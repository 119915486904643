@import "./variables.scss";

.possitionTop {
  position: relative;
  top: 1em;
}

.displayNone {
  display: none;
}

.sessionsContainer {
  display: flex;
  flex-direction: column;

  .introParagraph {
    width: 73%;
  }

  .tableContainer {
    width: 100%;

    .center {
      margin: 1.25em auto;
      text-align: center;
    }

    .sessionsTable {
      background-color: #f3f6f8;
      padding: 1em;
      margin-bottom: 2em;
      overflow-x: inherit;
      display: flex;

      @media only screen and (max-width: 799px) {
        white-space: nowrap;
        overflow-x: scroll;
      }

      .sessionsTableHeader {
        display: inherit;
      }

      .selectedSessionsCount {
        display: inherit;
        align-items: normal;
        position: relative;
        top: 0;
        left: 0.7em;
        border: solid;
      }

      .sessionsContainer {
        max-height: 45em;
        overflow-y: auto;
        min-width: 48em;

        .sessionsRow {
          display: grid;
          padding: 0.5em 1em;
          border-bottom: 2px solid $light-gray;
        }

        .sessionsContainerHeader {
          font-weight: 600;
        }

        .noWrap {
          display: block;
          max-width: 16em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .sessionItem {
          display: flex;

          .sessionItemCheckbox {
            margin-right: 0.5em;
          }

          .greenText {
            color: #00670c;
          }

          .orangeText {
            color: #d12f02;
          }

          .grayText {
            color: $text-darker-gray;
          }
        }
      }
    }
  }
}
