@import './variables.scss';

.upcomingLiveEvents {
  width: 100%;
  padding: 1.25em;
  position: relative;
  padding-bottom: 5em;
  .actionsContainer {
    position: absolute;
    bottom: 2em;
  }
}

.selectedSessionsCount {
  margin: 0 auto;
  min-height: 3.5em;
  .selectedSessionsCount {
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    width: 50%;
    color: #323130;
    border: solid;
    button: {
      margin-left: "5%"
    }
  }
  .sendInvitesButtonContainer {
    position: relative;
    border: hidden;
    margin-left: 0.5em;
    .sendInvitesIcon {
      display: flex;
      margin-right: 0.5em;
    }
    /* Send Invite  */
    .sendInvitesText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #323130;
    }
  }
}
.sessionsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 !important;
  .sessionsRow {
    display: grid;
    padding: 0.75em 0;
    border-bottom: 2px solid $light-gray;
    // background-color: $white;
    .eventDetails {
      display: flex;
      width: 100%;
      gap: 0.5em;
      .sessionItem {
        display: flex;
        .displayCol {
          flex-direction: column;
        }
        .sessionItemCheckbox {
          margin-right: 0.5em;
        }
        .dateContainer {
          margin-bottom: 0.5em;
          .sessionTime {
            color: $text-darker-gray;
            margin-right: 1em;
          }
        }
        .greenText {
          color: #00670c;
        }
        .orangeText {
          color: #d12f02;
        }
        .grayText {
          color: $text-darker-gray;
        }
        .noWrap {
          display: block;
          max-width: 16em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .dateTimeWraper {
        min-width: 9em;
        flex-direction: column;
        .timeContainer {
          min-width: 6em;
          display: flex;
          flex-direction: column;
          .eventTime {
            margin: 0.25em 0;
          }
        }
      }
      .eventDetailsWrapper {
        display: flex;
        justify-content: space-between;
        gap: 1em;
        width: 100%;
        .sessionTitleHeader,
        .invitationStatusHeader {
          margin-left: 2em;
        }
        .sessionTitle {
          display: flex;
          min-width: 8em;
          line-height: 1.75em;
          span {
            width: 95%;
          }
          .sessionTimer {
            position: relative;
            left: 0.5em;
            background-color: $purple-extra-light;
            color: $purple-dark;
            border-radius: 0.35em;
            padding: 0.15em 0.75em;
          }
        }
        .sessionTitleHeader,
        .sessionTitle {
          flex-grow: 2;
        }
        .invitationStatusHeader,
        .invitationStatus {
          min-width: 8em;
        }
        .eventActions {
          display: flex;
          flex-grow: 1;
          gap: 0.5em;
          @media only screen and (max-width: 550px) {
            justify-content: left;
            margin-top: 1em;
          }
          .eventActionsButton {
            padding: 1em;
            min-width: fit-content;
            height: 0.5em;
            border-radius: 0.35em;
          }
        }
      }
      .separator {
        border-right: solid 0.25em $purple;
        border-radius: 3em;
        margin: 0 0.6em;
      }
    }
  }
  .sessionsContainerHeader {
    margin-bottom: 0 !important;
    .icon {
      margin-left: 0.5em;
    }
  }
}

//Tab
@media only screen and (min-width: 426px) and (max-width: 950px) {
  .upcomingLiveEvents {
    width: 95%;
    .eventDetailsWrapper {
      @media only screen and (max-width: 550px) {
        flex-direction: column;
      }
    }
  }
}

//Mobile
@media only screen and (max-width: 425px) {
  .upcomingLiveEvents {
    width: 90%;
    .eventDetails {
      .timeContainer {
        width: 5em;
      }
      .eventDetailsWrapper {
        flex-direction: column;
      }
    }
  }
}
