.img-style {
  position: absolute;
  width: 100%;
  margin-left: -20px;
  margin-top: -10%;
  z-index: -1;
}

.spinner-layout {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.rowDesktop {
  display: grid;
  gap: 1.5em;
  grid-template-rows: 1fr 1fr;
}

.columnMobile {
  display: grid;
  gap: 1.5em;
  grid-column-start: 1;
  grid-column-end: 3;
}

.oneRow {  
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
}

.separator {
  border: 0.75px solid;
  opacity: 35%;
}
