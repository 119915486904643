@import './variables.scss';

.ms-Layer {

  .sendInvitesDialog {
    .modalFreeze {
      pointer-events: none;
    }
    .ms-Overlay {
      background: #000 0% 0% no-repeat padding-box !important;
      opacity: 0.75 !important;
    }

    .ms-Dialog-main {
      max-width: 38em !important;
      overflow: inherit;

      @media screen and (max-width: 768px) {
        width: 90% !important;
      }

      .ms-Modal-scrollableContent {
        .ms-Dialog--close {
          .ms-Dialog-header {
            .ms-Dialog-title {
              padding: 2em 0 0 1.25em;
              font-size: 20px;
              font-weight: 700;
              margin-right: 4em;
            }
          }

          .ms-Dialog-inner {
            font-size: 15px;
            padding: 20px 0 0 0;

            .ms-Dialog-content {
              width: 90%;
              text-align: left;
              margin: 0 auto;
            }

            .sendInvitesActions {
              padding-top: 1em;
              padding-bottom: 2.5em;
              margin: 0 auto;

              @media screen and (max-width: 425px) {
                padding-left: 0;
                padding-right: 0;
              }

              .sendInvitesActionsWrapper {
                display: flex;
                flex-direction: row;
                float: right;
                justify-content: right;
                gap: 1em;
              }

              .ms-Button {
                @media screen and (max-width: 425px) {
                  padding-top: 1.5em;
                  padding-bottom: 1.5em;
                }

                width: 7em;
                height: 3em;
                border-radius: 3px;

                &:focus {
                  border: $border-black;
                }
              }
            }
          }
        }
      }
    }
  }
}
