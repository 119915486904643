// Colors
$white: #fff;

$light-gray: #f3f6f8;

$card-border: #d5d5d5;

$background-gray: #ededed;

$gray: #c6c6c6;

$border-gray: rgba(118, 118, 118, 0.5);

$text-light-gray: rgb(210, 208, 206);

$text-dark-gray: rgb(128, 128, 128);

$text-darker-gray: rgb(108, 108, 108);

$border-black: 1px solid #000;

$black: #000;

$purple: #7a55bf;

$purple-light: #cf91ff;

$purple-extra-light: #f1dfff;

$purple-dark: #3d2b60;

$dark-blue: #285b8f;

$primary-blue: #0078d4;

$secondary-blue: #0075c4;

$spinner-label-blue: #0a5a99;

$btn-blue: #317aaa;

$theme-tertiary: #5ca9e5;

$link-text: #0065b3;

$dark-mode-aqua: #0ff;

$text-green: #07695b;

$text-green-success: #00670c;

$bar-color: #046714;

// Widths
$max-header-footer-width: 1215px;

$max-header-width: 1215px;

$max-left-width: 300px;

$max-middle-width: 40px;

$max-content-width: 850px;

$languages: zh-CN, ko-KR, ja-Jp;

// Font weights
$semilight: 300;

$regular: 400;

$semibold: 600;

$bold: 700;
