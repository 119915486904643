@import "./variables.scss";

.contentWrapper {
  height: 28rem;
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .dialogContentLeft {
      width: 50%;
      padding-right: 2rem;
      h2 {
        font-size: 1.75rem;
        margin: 1rem 0;
      }
      .message {
        font-size: 14px;
        margin-bottom: 1em;
        color: var(--colorNeutralForeground2);
      }
      .list {
        margin-top: 1em;
        .listItem {
          margin: 1em 0;
          color: var(--colorNeutralForeground2);
          .iconList {
            position: relative;
            top: 0.4em;
            padding: 0;
            margin-right: 0.5em;
          }
        }
      }
    }
    .dialogContentRight {
      padding: 0;
      width: 35vw;
      .imageTooltip {
        position: relative;
        left: 6em;
        top: 3em;
      }
      .imageWrapper {
        position: relative;
        top: 3rem;
        float: right;
        img {
          max-width: 100%;
          max-height: 30em;
        }
      }
    }
  }
}

.customButton {
  width: 8em;
  height: 3em;
  display: flex;
}

//Tablet and Mobile
@media only screen and (max-width: 750px) {
  .dialogSurfaceMobile {
    padding: 1rem 0;
    height: 100vh;
    width: 100vw;
  }
  .contentWrapper {
    min-height: 40rem;
    padding-right: 3rem;
    .content {
      flex-direction: column;
      .dialogContentLeft {
        width: 100%;
        padding: 0;
        h2 {
          font-size: 1.75rem;
          margin: 1rem 0;
        }
        .message {
          font-size: 14px;
          margin-bottom: 1em;
        }
        .list {
          margin-top: 1em;
          .listItem {
            margin: 1em 0;
            .iconList {
              position: relative;
              top: 0.4em;
              padding: 0;
              margin-right: 0.5em;
            }
          }
        }
      }
      .dialogContentRight {
        width: 45vw;
        margin: 0 auto;
        .imageTooltip {
          position: relative;
          left: 6em;
          top: 3em;
        }
        .imageWrapper {
          top: 1.5rem;
        }
      }
    }
  }

  .actionsMobile {
    margin: 0 auto;
    margin-bottom: 1rem;
    padding-right: 3rem;
    .singleButtonMobile {
      width: 100%;
    }
    .customButton {
      width: 50%;
    }
  }
}
