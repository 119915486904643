@import './variables.scss';

.sessionDialogContent {
  width: auto;
  min-width: 80em;
  margin-bottom: 20em !important;
  overflow-y: scroll !important;
  z-index: 0 !important;
  @media only screen and (min-width: 800px) and (max-width: 949px) {
    min-width: 95%;
  }
  .ms-Dialog-inner {
    padding: 0;
    .dialogContentWrapper {
      display: flex;
      height: 35em !important;
      .dialogContent {
        display: flex;
        .dialogContentLeft {
          padding: 0 4em;
          width: 35em;
          h2 {
            font-size: 2.5em;
            margin: 0.5em 0;
          }
          .message {
            font-size: 14px;
            margin-bottom: 1em;
          }
          .list {
            margin-top: 1em;
            .listItem {
              margin: 1em 0;
              .iconList {
                position: relative;
                top: 0.4em;
                padding: 0;
                margin-right: 0.5em;
              }
            }
          }
        }
        .dialogContentRight {
          padding: 0;
          width: 50%;
          .imageTooltip {
            position: relative;
            left: 6em;
            top: 3em;
          }
          .imageStep1 {
            position: relative;
            top: 4.3em;
            @media only screen and (min-width: 800px) and (max-width: 949px) {
              top: 9em;
            }
            img {
              width: 100%;
            }
          }
          .imageStep2 {
            position: relative;
            top: 3em;
            img {
              width: 100%;
            }
          }
          .imageStep3 {
            position: relative;
            top: 3em;
            right: -1em;
            img {
              width: 100%;
            }
          }
        }
      }
      .actions {
        position: absolute;
        bottom: 4em;
        left: 4em;
        display: flex;
        gap: 0.5em;

        .actionsButton {
          width: 10em;
          height: 3.5em;
          border-radius: 0.4em;
          font-size: 14px;
          span {
            max-width: fit-content;
            justify-content: center;
            margin: 0 auto;
          }
        }
        .beforeButton {
          color: $purple-dark;
          border-style: none;
          border: none;
          &:hover {
            background-color: $white;
            color: $purple;
            border: solid 2px $purple;
          }
          .icon {
            margin-right: 1em;
          }
        }
        .nextButton {
          background-color: $purple;
          color: $white;
          border: none;
          &:hover {
            background-color: $white;
            color: $purple;
            border: solid 2px $purple;
          }
          .icon {
            margin-left: 1em;
          }
        }
      }
    }
  }
}

//Tablet and Mobile
@media only screen and (max-width: 799px) {
  .sessionDialogContent {
    width: 100vw;
    max-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    .ms-Dialog-inner {
      padding: 0;
      .dialogContentWrapper {
        width: 100vw;
        height: 100vh;
        overflow-x: visible;
        overflow-y: visible;
        .dialogContent {
          display: flex;
          flex-direction: column;
          max-height: 100vh;
          .dialogContentLeft,
          .dialogContentRight {
            padding: 0 2em;
            width: 100%;
          }
          .dialogContentRight {
            text-align: center;
            .imageTooltip {
              position: relative;
              left: 1em;
              top: 2em;
            }
            .imageStep1 {
              top: 1em;
              right: -2em;
              img {
                float: right;
                max-width: 24em;
              }
            }
            .imageStep2 {
              top: 1em;
              margin: 0 auto;
              img {
                max-width: 24em;
                margin: 0 auto;
              }
            }
            .imageStep3 {
              top: 1em;
              right: 0;
              max-width: 20em;
              margin: 0 auto;
              img {
                max-width: 24em;
              }
            }
            .imageStep4 {
              margin: 0 auto;
              img {
                width: 90%;
              }
            }
          }
        }
        .actions {
          position: fixed;
          bottom: 0;
          left: 2em;
          display: flex;
          gap: 0.5em;
          margin: 2em 0;
        }
      }
    }
  }
}
