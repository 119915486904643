.border-style {
  border-style: solid;
  border-width: 1px;
  border-color: white;
}

.has-task-layout {
  display: grid;
  gap: 0.25rem;
  overflow-x: hidden;
  min-width: 18rem;
  align-content: start;
}

.no-task-layout {
  display: grid;
  gap: 1.8rem;
  overflow-x: hidden;
  min-width: 18rem;
}

.add-btn {
  color: var(--colorBrandForeground1);
  margin-left: 0.35rem;
  padding: 0;
}

.input {
  border: none;
  outline: medium;
  height: 1.75rem;
  margin-left: -0.35rem;
  font-size: 0.875rem;
  color: var(--colorNeutralForeground1);
}

.focused-color {
  background-color: var(--colorNeutralBackground6);
}

.non-focused-color {
  background-color: var(--colorNeutralBackground3);
}

.add-task-container {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 1rem;
  align-items: center;
  border-radius: 4px;
  height: 2.25rem;
  padding-left: 0.875rem;
  padding-right: 0.7rem;
}

.add-btn-enter {
  border-radius: 4px;
  width: max-content;
  height: 1.5rem;
  border: none;
  font-weight: 600;
  text-align: center;
  background-color: var(--colorNeutralStroke1Hover);
}

.add-btn-leave {
  border-radius: 4px;
  width: max-content;
  height: 1.5rem;
  border: none;
  font-weight: 600;
  text-align: center;
  background-color: var(--colorNeutralStrokeOnBrand2);
}

.existing-task-layout {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 1rem;
  align-items: center;
  background-color: var(--colorNeutralBackground3);
  border-radius: 4px;
  height: 2.25rem;
  padding-left: 0.875rem;
  padding-right: 0.7rem;
}
