@import './variables.scss';

  .iconSearchBox {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.167s ease 0s;
  }

  .sessionsSearchBox {
    display: inherit;
    align-items: end;
    position: relative;
    padding-left: 35%;
    margin-bottom: 1.5em;
    width: 70%;
    height: 3em;
    border: solid;

    .sessionsSearchBoxInfo {
      padding-left: 20em;
      margin-bottom: -1.25em;
      z-index: 2 !important;
      position: relative;
      align-items: flex-end;
    }

    .searchSugestions {
      z-index: 1;
      width: 15.5em;
      position: absolute;
      top: 2.7em;
      right: 0;
      background-color: $white;
      padding: 0.5em 1em;
      border: 1px solid $border-gray;
    }
  }