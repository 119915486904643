@import "./variables.scss";

.progressIndicator {
  display: inline-flex;

  .indicator {
    border: solid 6px var(--colorBrandForegroundOnLightHover);
    background-color: var(--colorBrandForegroundOnLightHover);
    border-radius: 50%;
    margin-right: 0.5em;
  }

  .inactiveView {
    border: solid 2px #C8C8C8;
    background-color: #C8C8C8;
    position: relative;
    top: 0.1em;
    width: 0.6em;
    height: 0.6em;
  }
}

.labelIndicator {
  position: relative;
  top: 0.1rem;
  margin-left: 0.5em;
  color: $text-darker-gray;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 14px !important;
}
