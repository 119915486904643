@import "./variables.scss";

.syllabusHeader {
  display: flex;
  justify-content: flex-start;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 0.5em;
  @media only screen and (max-width: 1023px) {
    gap: 0;
    flex-direction: column;
    margin-bottom: 1.5em;
  }
  
  .dropdownContainer {
    padding: 0.65em 0;
    @media only screen and (max-width: 1023px) {
      padding: 1em 0 0 0;
    }
    label {
      padding: 0.75em 0;
      min-width: max-content;
    }
    div {
      background-color: #ebebeb;
      border-radius: 5px;
    }
    div,
    span {
      width: 100%;
      @media only screen and (min-width: 640px) and (max-width: 1023px) {
        gap: 0;
      }
      @media only screen and (max-width: 639px) {
        width: 100%;
        gap: 0;
      }
    }
  }

  .filterButton {
    background-color: $light-gray;
    color: #5b5fc7;
    padding-left: 0.5em;
    &:focus {
      border-color: $black;
    }

    .filterIcon {
      color: $black;
    }
  }
}

.grayBackground {
  border: 1px solid var(--colorNeutralForeground1);
  border-radius: 4px;
  padding: 0.45em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 0.75em;
  background-color: var(--colorNeutralBackground1);
  color: var(--colorNeutralForeground1);
}

.syllabusTableWrapper {
  width: auto; 
  overflow: auto;
  min-height: 20em;
  display: flex;
  flex-direction: column;
}

.alignCenter {
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
}

.flaggedColor {
  color: #e93a39;
}

.cellLoader {
  float: left;
  padding-left: 0.4em;
}
.platformNameWrapper {
  white-space: nowrap;
}
.deselectAll {
  width: 35%;
  cursor: pointer;
  float: left;
}
.selectAll {
  width: 25%;
  margin-right: 0.7em;
  text-align: right;
  cursor: pointer;
  float: right;
}

.customOption {
  display: flex;
  direction: rtl;
  column-gap: 15px !important;
  span {
    border-radius: 10px !important;
    border-color: transparent !important;
    width: 18px;
    height: 18px;
  }
}

.closeBtn {
  width: 100%;
  height: 2.6em;
  border-radius: 0.4em;
  background-color: #4F52B2 !important;
}

.taskTypedropdown {
  min-width: 9em !important;
  width: 100% !important;
}
.selected {
  // background-color: #0078d4 !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
}
.tasktypefilterButton {
  width: 100%;
  color: #006cbe;
  span {
    width: 2em;
  }
  .filtertext {
    vertical-align: super;
    padding-left: 0.3em;
    color: #9299F7;
  }
}
.dot {
  height: 1.8em;
  width: 2em;
  background-color: #2e2e2e;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  margin-right: 0.2em;
  font-size: 12px;
  font-weight: 400;
}

.focusAreaSelectedButton {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  .dot {
    width: 2em !important;
    margin-right: 0.75em;
  };
}
.iconColor {
  color: #fff;
  font-size: 12px;
}
.topicSize {
  font-size: 12px;
}
.showSelectedFocusArea {
  margin: 0.75em 0 0 0;
}

.seeMoreButtonContainer {
  width: 8em;
  margin: 0 auto;
  text-align: center;
}
.yourProgressTableWrapper {
  width: auto; 
  overflow: auto;
}
