.taskPopoverWrapper {
    width: 325px;
    padding: .3em;

    .leftCol {
        float: left;
        text-align: left;
        margin-left: .2em;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        // color: #242424;

    }

    .rightCol {
        width: 1em;
        float: right;
        text-align: right;
    }

    .tasknameWraper {
        width: 100%;
        height: 3em;
        display: flex;

        // color: #000000;  
        .icon {
            margin: .2em 0 0 0;
        }

        .taskname {
            font-weight: 400;
            font-size: 14px;
            margin: 0 0 0 .2em;

        }

    }

    .action {
        display: inline-flex;
        margin-top: 1em;

        .actionwrapper {
            padding: 0 0.5em 0 0;
        }

        .button {
            height: 24px;
            font-weight: 600;
            font-size: 12px;
            line-height: 17px;
            padding-right: 1em;
            outline: none;
        }

    }
}

.flaggedColor {
    color: #E93A39
}